::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #e1e3eb;
}

::-webkit-scrollbar-thumb {
  background: #a29eac;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 4px;
  background: #555;
}