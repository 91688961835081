.auth-body,
.auth-body form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto;
}

.logo-auth {
    width: 12.5rem;
    height: 12.5rem;
    object-fit: contain
}

.title-auth {
    text-align: center;
    color: #000;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.17
}

.subtitle-auth {
    font-size: 1.3rem;
    font-weight: 200;
    line-height: 1.71;
    text-align: center;
    margin: 16px 0 24px
}

@media only screen and (max-width:768px) {

    .auth-body,
    .auth-body form,
    .main-content {
        width: 100% !important
    }

    .auth-body {
        width: 100%;
        padding: 0 8px
    }
}