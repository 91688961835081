.plans {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.content-plan {
    font-size: 1rem;
    font-weight: 300;
    text-align: initial
}

.card-plan {
    width: 17rem;
    height: 28rem;
    position: relative
}

.card-img {
    width: 17rem !important;
    height: 28rem
}

.img-plan {
    width: 128px;
    height: 132px;
    position: absolute;
    top: -67px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%
}

.body-plan {
    padding: 0 20px;
    width: 100%;
    position: absolute;
    height: 78%;
    top: 64px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between
}

.title-plan {
    margin-bottom: 8px;
    margin-top: 18px;
    font-size: 18px;
    font-weight: 700;
    justify-content: center
}

.plan-items-img {
    max-width: 2rem;
    max-height: 2rem;
    border-radius: 8px
}

.margin-end-sm {
    margin-inline-end: 8px
}

.line-through {
    text-decoration-line: line-through;
    color: #acacac !important;
}

@media only screen and (max-width: 1024px) {
    .card-img {
        width: 14rem !important;
    }

    .card-plan {
        width: 14rem;
    }

    .img-plan {
        width: 104px;
        height: 108px;
        top: -40px;
    }
}

@media only screen and (max-width: 930px) {
    .plans {
        flex-direction: column;
        align-items: center;
    }

    .card-plan {
        margin-top: 40px;
    }
}