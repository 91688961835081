.identity {
  .stack-img {
    position: absolute;
    bottom: 30px;
    left: 30px;
    img {
      width: 100%;
    }
    @media (max-width: 1400px) {
      img {
        width: 80%;
        float: left;
      }
    }
  }
  a {
    color: #016ee5;
    font-family: yekan-bold;
    text-decoration: none;
  }
  .MuiFormLabel-root {
    left: unset !important;
  }
}
a {
  color: #016ee5;
  font-family: yekan-bold;
  text-decoration: none;
}
