@import 'fonts.scss';
@import 'scrollbar.scss';

.MuiDataGrid-cell--withRenderer {
  pointer-events: all !important;
}

.MuiButton-outlined {
  border: 1px solid !important;
}

.MuiButtonBase-root {
  font-family: yekan-medium !important;
}

.MuiAlert-icon {
  margin-right: 0px !important;
  margin-left: 8px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #C4C4C4 !important;
}

.css-sg2jfx-MuiInputBase-root-MuiInput-root.Mui-error:after {
  border-bottom-color: #b1b1b1 !important;
}

.css-1a0wq1u-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
  border: 1px solid #e8eaeeb3
}

.css-gzlx6l-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
  border: 1px solid #e8eaeeb3
}

body {
  margin: 0;
  direction: rtl;
  font-family: yekan-medium;
  background-color: #f7f8f9 !important;
}

.pa-md {
  padding: 16px;
}

.py-md {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pa-sm {
  padding: 8px;
}

.px-sm {
  padding-right: 8px;
  padding-left: 8px;
}

.pr-xs {
  padding-right: 5px;
}

.pr-sm {
  padding-right: 8px;
}

.pt-sm {
  padding-top: 8px;
}

.pt-xl {
  padding-top: 46px !important;
}

.py-xl {
  padding-top: 46px;
  padding-bottom: 46px;
}

.pb-md {
  padding-bottom: 16px;
}

.pa-sm {
  padding: 6px;
}

.pb-sm {
  padding-bottom: 6px;
}

.pr-md {
  padding-right: 12px;
}

.pl-md {
  padding-left: 12px;
}

.pl-sm {
  padding-left: 4px;
}

.mt-sm {
  margin-top: 8px;
}

.mt-lg {
  margin-top: 32px;
}

.mb-lg {
  margin-bottom: 32px;
}

.mt-md {
  margin-top: 16px;
}

.mb-md {
  margin-bottom: 16px;
}

.mb-sm {
  margin-top: 8px;
}

.mr-md {
  margin-right: 16px;
}

.ml-sm {
  margin-left: 6px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.element-center {
  margin: 0 auto;
}

.flex {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end
  }

  &.justify-center {
    justify-content: center
  }

  &.column {
    flex-direction: column;
  }
}

.full-width {
  width: 100%;
}

.hide {
  display: none !important;
}

.pointer {
  cursor: pointer;
}