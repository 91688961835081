.box-payment {
    background-color: #edededcc;
    border-radius: 24px;
}

.icon-img-md {
    width: 1.25rem !important;
    height: 1.25rem !important
}

.icon-img-sm {
    width: .7rem !important;
    height: .7rem !important
}

.status-img {
    width: 120px;
}