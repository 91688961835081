.font-medium {
  font-family: yekan-medium;
  font-weight: 500;
}
.font-bold {
  font-family: yekan-bold;
  font-weight: 500;
}
.font-bolder {
  font-family: yekan-heavy;
  font-weight: 700;
}
.font-heavy {
  font-family: yekan-heavy;
  font-weight: 900;
}
.fa-number {
  font-family: yekan-number-regular;
}
.fa-number-bold {
  font-family: yekan-number-bold;
}
.fa-number-fat {
  font-family: yekan-number-fat;
}
.size-1 {
  font-size: 8px;
}
.size-2 {
  font-size: 10px;
}
.size-3 {
  font-size: 12px;
}
.size-4 {
  font-size: 14px;
}
.size-5 {
  font-size: 16px;
}
.size-6 {
  font-size: 18px;
}
.size-7 {
  font-size: 20px;
}
.size-8 {
  font-size: 22px;
}
.size-9 {
  font-size: 24px;
}
.size-10 {
  font-size: 16px;
}