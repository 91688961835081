.banner-style {
    background-color: #e30d0d;
    border-radius: 16px;
    text-align: start;
    padding: 6px;
    width: 100%;
    color: #fff
}

.title-view {
    display: flex;
    text-align: initial;
    margin-bottom: 20px;
    align-items: center;

    img {
        border-radius: 26px !important;
        width: 7.4rem !important;
        height: 7.4rem !important
    }
}

.margin-l-md {
    margin-inline-end: 16px
}

.header-modal-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.plan-desc-subscripion {
    font-size: 14px;
    text-align: initial
}

@media only screen and (max-width: 420px) {
    .title-view {
        img {
            width: 6.4rem !important;
            height: 6.4rem !important
        }

        .margin-l-md {
            margin-inline-end: 8px
        }
    }
}