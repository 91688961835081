@font-face {
    font-family: yekan-number-regular;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/fa-num/Yekan\ Bakh\ FaNum\ 05\ Medium.eot");
    src: url("../fonts/fa-num/Yekan\ Bakh\ FaNum\ 05\ Medium.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */ url("../fonts/fa-num/Yekan\ Bakh\ FaNum\ 05\ Medium.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("../fonts/fa-num/Yekan\ Bakh\ FaNum\ 05\ Medium.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: yekan-number-bold;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/fa-num/Yekan Bakh FaNum 06 Bold.eot");
    src: url("../fonts/fa-num/Yekan Bakh FaNum 06 Bold.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */ url("../fonts/fa-num/Yekan Bakh FaNum 06 Bold.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("../fonts/fa-num/Yekan Bakh FaNum 06 Bold.ttf")
        format("truetype");
  }
  
  
  @font-face {
    font-family: yekan-number-fat;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/fa-num/Yekan Bakh FaNum 08 Fat.eot");
    src: url("../fonts/fa-num/Yekan Bakh FaNum 08 Fat.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */ url("../fonts/fa-num/Yekan Bakh FaNum 08 Fat.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("../fonts/fa-num/Yekan Bakh FaNum 08 Fat.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: yekan-medium;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/fa-en/Yekan Bakh Fa-En 05 Medium.eot");
    src: url("../fonts/fa-en/Yekan Bakh Fa-En 05 Medium.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */ url("../fonts/fa-en/Yekan Bakh Fa-En 05 Medium.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("../fonts/fa-en/Yekan Bakh Fa-En 05 Medium.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: yekan-bold;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/fa-en/Yekan Bakh Fa-En 06 Bold.eot");
    src: url("../fonts/fa-en/Yekan Bakh Fa-En 06 Bold.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */ url("../fonts/fa-en/Yekan Bakh Fa-En 06 Bold.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("../fonts/fa-en/Yekan Bakh Fa-En 06 Bold.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: yekan-heavy;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/fa-en/Yekan Bakh Fa-En 07 Heavy.eot");
    src: url("../fonts/fa-en/Yekan Bakh Fa-En 07 Heavy.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */ url("../fonts/fa-en/Yekan Bakh Fa-En 07 Heavy.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("../fonts/fa-en/Yekan Bakh Fa-En 07 Heavy.ttf")
        format("truetype");
  }
  