$primary: #1d1d1d;

button {
    &.MuiPaginationItem-firstLast, &.MuiPaginationItem-previousNext {
        width: 32px;
        height: 32px;
        color: #ffffff;
        border-radius: 8px;
        background-color: $primary;
        svg {
            font-size: 20px;
            font-weight: bold;
        }
    }
}